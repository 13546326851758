@media (max-width:1199px) {
    .sidebar {
        width: 57%;
    }
    .circle {
        font-size: 25px;
    }
    .circle2 {
        font-size: 25px;
    }
}

@media (max-width:1024px) {
    .sidebar {
        width: 60%;
    }
    .circle {
        font-size: 22px;
    }
    .circle2 {
        font-size: 22px;
    }
}

@media (max-width:985px) {
    .sidebar {
        width: 75%;
    }

    .navbar-vertical.navbar-expand-lg.show{
        width: 100% !important;
    }

    .circle {
        font-size: 19px;
    }

    .circle2 {
        font-size: 19px;
    }
    
    .custom-display{
        display: none;
    }
}

@media (max-width:991px) {
    .navbar-wide1{
        width: 10rem !important;
        left: 0rem;
    }

    .navbar-narrow2{
        width: 10rem !important;
        left: -10rem;
    }

    .nav-button{
        position: fixed;
        right: unset;
        left: 0;
        transition: all .2s ease-in-out;
    }

    .open{
        left: 10rem;
        right: unset;
    }
}



@media (max-width:767px) {
    .sidebar {
        width: 100%;
    }

    .inside-sidebar {
        height: unset;
        overflow: auto;
    }

    .item{
        width: 25%;
    }

    .circle{
        width: 50%;
        &:hover {
            background-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
        }
    }

    .circle2{
        width: 50%;
    }
}

@media (max-width:575px) {
    .repeater-field{
        width: 100%;
    }

    .repeater-buttons{
        width: unset;
    }

    .item{
        width: 33%;
    }

    .circle{
        width: 60%;
    }

    .circle2{
        width: 60%;
    }
}