.card-header {
    padding: 0.8rem 1rem !important;
    background-color: white !important;
    /* margin-top: 15px; */
    border-radius: unset !important;
    border-top: 1px solid #eceef3;
}

.navbar-light .navbar-collapse::before {
    display: none;
}

.toggle-icon-btn {
    position: absolute;
    left: 0;
    cursor: pointer;
}

.table td {
    font-size: .8125rem;
    white-space: nowrap;
    padding: 0.5rem 1.5rem !important;
}

.thead-light th {
    background-color: #e2e6e9 !important;
    color: #525f7f;
}

.thead-light {
    position: sticky;
    top: 0;
    z-index: 2;
}

.selectbox1 {
    position: sticky;
    left: 0;
    z-index: 3;
}

.selectbox {
    position: sticky;
    left: 0;
    background-color: white !important;
}

.table-responsive {
    border-radius: 0.75rem !important;
    min-height: 468px;
}

.myTable {
    max-height: 500px;
    overflow: auto;
}

.flex-lg-row {
    flex-direction: column !important;
}

.my-btn3 {
    background-color: transparent;
}

/* Dashboard.css */

.sidebar-overlay {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;

}

@keyframes slide {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slide-back {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

.sidebar {
    width: 50%;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    float: right;
}

.open {
    animation: slide .25s ease-in-out forwards;
}

.closed {
    animation: slide-back .25s ease-in-out forwards;
}

.sidebar .container1 {
    height: 100%;
    padding: 0 12px;
}

.sidebar .sidebar-item1 {
    height: 8%;
    border-bottom: 2px solid #5c60f5;
}

.sidebar .sidebar-item2 {
    height: 82%;
    overflow: auto;
    padding-bottom: 1rem;
}

.inside-sidebar {
    height: 100%;
    overflow: auto;
}

.sidebar-item3 {
    height: 10%;
    border-top: 1px solid gainsboro;
}

.close-btn {
    background: none;
    border: none;
    color: black;
    font-size: 18px;
    cursor: pointer;
}

.close-btn:hover {
    color: red;
}

.Lead-items {
    border-bottom: 1px solid #e7eaf0;
    padding: 8px 5px;
    color: black;
}

/* .other-screen {
    height: 80vh !important;
} */

.close-btn {
    /* position: absolute; */
    right: 0;
    top: 0;
}




.selected-columns {
    display: flex;
    flex-direction: column;
}

.selected-item {
    padding: 3px 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    border-radius: 4px;
    cursor: grab;
    position: relative;
}

.selected-item:active {
    cursor: grabbing;
}

.cross-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    height: 100%;
    width: 10%;
}

.form-select {
    padding: .75rem 1.5rem .75rem 1.25rem !important;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
    background-position: right 0.25rem center !important;

}

.form-control {
    padding: .75rem !important;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

.btn-outline-secondary {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

.mybtn {
    display: inline-block;
    font-weight: 500;
    line-height: 1.3;
    color: #525f7f;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0;
    width: 100%;
    font-size: 1rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;


    padding-bottom: 8px !important;
    padding-top: 8px !important;
    background-color: #cfd6df !important;
    border-color: #cfd6df !important;
    color: #000 !important;
}

.mybtn2 {
    border-right: unset !important;
    border-radius: unset !important;
    padding: 8px 10px !important;
}

.mybtn2:hover {
    background-color: white !important;
}

.addbtn {
    padding: 10px;
    border: 1px solid #cfd6df;
    text-align: end;
    border-top: unset;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.mybtn3 {
    color: royalblue;
    background-color: #f5f9fc;
    border: 1px solid #cfd6df;
    padding: 5px 20px;
}

.my-form {
    border-radius: unset !important;
}

.input-group:focus-within {
    box-shadow: unset !important;
}

.my-form:focus {
    /* border: unset !important; */
    border-color: #e7eaf0 !important;
}

.my-form-select {
    border-radius: unset !important;
    border-right: unset !important;
    /* background-position: right 0.25rem center !important; */
    border: 1px solid #e7eaf0;
}

.color1 {
    background-color: #e7eaf0;
}

.search-any {
    border: 1px solid #e7eaf0;
}

.form-select:focus {
    border-color: #e7eaf0 !important;
    box-shadow: unset !important;
}

.both {
    transform-origin: right;
    animation: test .3s linear forwards;
}

.both2 {
    transform-origin: right;
    animation: test2 .3s linear forwards;
}

@keyframes test {
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}

@keyframes test2 {
    from {
        transform: scaleX(1);
    }

    to {
        transform: scaleX(0);
    }
}

.filter-heading {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 10px 0;
}

.inner-content {
    padding: 12px 0;
    border: 1px solid #cfd6df;
    border-radius: 10px;
    background-color: #f5f9fc;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
}

.repeater-field {
    margin-left: auto !important;
    width: 90%;
}

.repeater-buttons {
    width: 12%;
}

.toggle-container {
    display: inline-block;
    padding: 0 12px;
}

.toggle-button {
    display: flex;
    width: 100%;
    border-radius: 50px;
    color: #ccc;
    cursor: pointer;
    position: relative;
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    padding: 3px;
}

.toggle-label {
    flex: 1;
    text-align: center;
    padding: 0px 15px;
}

.toggle-label.selected {
    background: #007bff;
    color: #fff;
    border-radius: 50px;
    padding: 0px 15px;
}

.toggle-label.selected2 {
    background: royalblue;
    color: #fff;
    border-radius: 50px;
    padding: 0px 15px;
}

.filter-select {
    position: relative;
}

.content-heading {
    padding-top: 1rem;
}

/* .toggle-button::before{
    content: '';
    position: absolute;
    height: 2.5rem;
    width: 1px;
    background-color: #ccc;
    bottom: -2.5rem;
    left: 20%;
}

.filter-select::before{
    content: '';
    position: absolute;
    width: 1rem;
    background-color: #ccc;
    height: 1px;
    top: 50%;
    left: -1rem;
} */

.content-heading {
    position: relative;
}

.cross {
    position: absolute;
    top: 4.1rem;
    right: 0;
    z-index: 1000;

    & i:hover {
        color: red;
    }
}

.star {
    color: red;
}

input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
    appearance: textfield;
    /* Chrome, Safari, Edge */
}

/* Hide spinners in Firefox */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.active>.page-link,
.page-link.active {
    z-index: 3;
    color: #525f7f !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    /* background-color: var(--bs-pagination-active-bg) !important;
    border-color: var(--bs-pagination-active-border-color) !important; */
}

.page-link {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-radius: 50% !important;
    padding: 6px 12px !important;
    margin: 0 1rem;
    border: unset !important;

}

.navbar-vertical.navbar-expand.show {
    background-color: #F7F7F5 !important;
    color: #5F5E5B;
    /* width: 10rem; */
}

.navbar-narrow {
    width: 3rem !important;
    /* transition: width 0.3s ease; */
}

.navbar-wide {
    width: 10rem !important;
    /* transition: width 0.3s ease; */
}

.navbar-wide .navbar-nav .nav-link {
    padding: .75rem .75rem !important;
}

.navbar-narrow .navbar-nav .nav-link {
    padding: .75rem 0 !important;
    justify-content: center;
}

.navbar-narrow .navbar-nav .nav-link i {
    text-align: center !important;
}

.faded {
    color: gray;
    /* font-size: 14px; */
}

.navbar-vertical .navbar-nav .nav-link {
    /* justify-content: center; */
    /* text-align: center; */
    background-color: transparent !important;
    /* border-top: 1px solid gray; */
    /* border-bottom: 1px solid gray; */
    width: 100%;
    font-size: 16px !important;
    color: #5F5E5B !important;
    min-height: 51px;
}

.nav-button {
    background: transparent;
    position: absolute;
    top: 0.8rem;
    right: -30px;
    z-index: 1001;
    color: white;
    cursor: pointer;
}

.navbar-vertical .navbar-nav .nav-link i {
    color: #5F5E5B !important;
}

.myicon {
    background-color: white;
    border-radius: 50%;
    color: lightcoral;
}

.fa-circle-user {
    font-size: 35px;
}

#alertId {
    position: fixed;
    z-index: 2000;
    /* width: 100%; */
    padding: 0px 0;
    right: 0;
    top: 0;
    font-size: large;
    transform-origin: top;
    animation: rotate .3s linear forwards;
}

/* role manager style */

.role_heading {
    padding: 0.5rem 1rem;
    border: 2px solid #e7eaf0;
}

.role_manage {
    padding: 1.5rem 1rem;
    border: 2px solid #e7eaf0;
    border-top: unset;
}

.my-form-select2 {
    border: 1px solid #cfd6df;
    padding: 0 .5rem;
    border-radius: 8px;
}














/* Basic styling for the form container */
.form-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Responsive design */
@media (max-width: 600px) {
    .form-container {
        padding: 15px;
    }

    .form-group2 {
        margin-bottom: 15px;
    }

    .user-select {
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid #ccc;
    }

    .user-btn {
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        border: none;
        background-color: #007bff;
        color: white;
        font-size: 16px;
        cursor: pointer;
    }

    .user-btn:hover {
        background-color: #0056b3;
    }
}

.form-group2 {
    margin-bottom: 20px;
}

label {
    display: block;
}

.user-select {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.user-btn {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.user-btn:hover {
    background-color: #0056b3;
}










/* form style */

.panel {
    border: 2px solid #e7eaf0;
}

.panel-body {
    padding: 15px;
}

.my-form-select3 {
    width: 100%;
    padding: 5px;
    border-color: #e7eaf0;
}

.form-input {
    width: 100%;
    padding: 5px;
    border: 1px solid #e7eaf0;
}

.form-group {
    margin: 10px 0;
}

.heading {
    margin: 10px 0;
}

.instructions {
    background-color: #f5f9fc;
}

.instructions ul li {
    list-style-type: circle;
}

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1000;
    box-shadow: rgb(194 197 201) 0px 4px 20px;
}














/* slider style */


.slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    /* Adjust based on your design */
}

.d-flex {
    display: flex;
    flex-wrap: nowrap;
}

.item {
    width: 20%;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    flex: 0 0 auto;

}

.arrow-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ddd;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.prev {
    background-color: transparent;
    left: 0;
}

.next {
    background-color: transparent;
    right: 0;
}

















/* Add these styles to your CSS file or a <style> tag in your component */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}














.loader {
    margin: 0 auto;
    width: 60px;
    height: 50px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);

    >div {
        height: 100%;
        width: 8px;
        /* display: inline-block; */
        float: left;
        margin-left: 2px;
        -webkit-animation: delay 0.8s infinite ease-in-out;
        animation: delay 0.8s infinite ease-in-out;
    }

    .bar1 {
        background-color: #754fa0;
    }

    .bar2 {
        background-color: #09b7bf;
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
    }

    .bar3 {
        background-color: #90d36b;
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
    }

    .bar4 {
        background-color: #f2d40d;
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
    }

    .bar5 {
        background-color: #fcb12b;
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
    }

    .bar6 {
        background-color: #ed1b72;
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
    }
}


@-webkit-keyframes delay {

    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.05)
    }

    20% {
        -webkit-transform: scaleY(1.0)
    }
}

@keyframes delay {

    0%,
    40%,
    100% {
        transform: scaleY(0.05);
        -webkit-transform: scaleY(0.05);
    }

    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}






.square {
    border: 2px solid #007bff;
    color: #007bff;
    border-radius: 4px;
    padding: 1px 4px;
    aspect-ratio: 1/1;

}

.highlight {
    color: #007bff;
}

.per-icon {
    width: 35px;
    margin: auto;
    border: 2px solid black;
    border-radius: 50%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}


.circle {
    width: 40%;
    aspect-ratio: 1/1;
    background-color: transparent;
    margin: auto;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    background-color: white;
    color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1)) !important;

    &:hover {
        background-color: rgb(13 110 253 / 75%);
        color: white !important;
        border-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    }
}

.circle2{
    width: 40%;
    aspect-ratio: 1/1;
    background-color: transparent;
    margin: auto;
    border-radius: 50%;
    border: 2px solid #0d6efdbf;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    background-color: white;
    color: #0d6efdbf !important;
    /* cursor: not-allowed; */
}

.col-item1 {
    border-top: 2px solid blue !important;
}

.col-item2 {
    border-top: 2px solid red !important;
}

.col-item3 {
    border-top: 2px solid green !important;
}

.col-item {
    border: 1px solid #e7eaf0;
    padding: 0.5rem 1rem;
}

.circle.active {
    background-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    color: white !important;
    border-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}

.myscroller {
    /* For most modern browsers (Chrome, Safari, Edge) */
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.myscroller::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.item a {
    text-decoration: none;
}

.edu-table td {
    padding: 0.5rem 1rem !important;

}

/* .edu-table th{
    word-break: break-all;
} */

.status-bar {
    height: 4px;
    background-color: #e7eaf0;
}

.current-status-bar {
    height: 100%;
    width: 33%;
    background-color: royalblue;
}


















/* Loader.css */
.logo-loader {
    position: relative;
    width: 200px;
    /* Adjust based on your logo size */
    height: 200px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-part {
    position: absolute;
    opacity: 0;
    animation: assemble 1s ease-in-out forwards;
}

.logo-part-1 {
    animation-delay: 0.2s;
    transform: translate(-50px, -50px);
}

.logo-part-2 {
    animation-delay: 0.4s;
    transform: translate(50px, -50px);
}

.logo-part-3 {
    animation-delay: 0.6s;
    transform: translate(-50px, 50px);
}

.logo-part-4 {
    animation-delay: 0.8s;
    transform: translate(50px, 50px);
}

@keyframes assemble {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0) scale(1);
    }
}



/* Light Mode Styles */
.light-mode {
    --background-color: #ffffff;
    --text-color: #000000;
    --card-background: #f8f9fa;
    --header-background: #ffffff;
    --input-background: #f0f0f0;
    --input-text-color: #000000;
    --button-background: #007bff;
    --button-text-color: #ffffff;
    --footer-background: #ffffff;
}

/* Dark Mode Styles */
.dark-mode {
    --background-color: #121212;
    --text-color: #ffffff;
    --card-background: #1e1e1e;
    --header-background: #1e1e1e;
    --input-background: #2a2a2a;
    --input-text-color: #ffffff;
    --button-background: #007bff;
    --button-text-color: #ffffff;
    --footer-background: #1e1e1e;
}

/* Apply the variables */
body {
    background-color: var(--background-color);
    color: var(--text-color);
}

.card {
    background-color: var(--card-background);
}

.card-header {
    background-color: var(--header-background);
}

input,
select,
textarea {
    background-color: var(--input-background);
    color: var(--input-text-color);
}

button {
    background-color: var(--button-background);
    color: var(--button-text-color);
}

footer {
    background-color: var(--footer-background);
}










.main-step {
    display: flex;
    list-style: none;
}

.steps {
    width: 16.66%;
    background-color: #8d9de9;
    border: 1px solid black;
    padding: 0.4rem 1rem;
    text-align: center;
}






.bx-pager {
    display: flex;
    align-items: center;
    height: 34px;
    border-left: 1px solid #8d9de9;
    overflow: auto;
    overflow-y: hidden;

    .bx-pager-item {
        display: flex;
        align-items: center;
        height: 100%;
        flex: 0 25%;
        background-color: gray;
        

        .bx-pager-link {
            text-decoration: none;
            color: white;
            font-size: 13px;
            flex: 1;
            padding-left: 16px;
            text-align: center;
            
        }

        .arrow {
            border: solid white;
            display: inline-block;
            padding: 15px;
            border-width: 0 5px 5px 0;
            transform: translateY(15.5px) rotate(-45deg) skew(-15deg, -15deg) translateX(18px);
            background-color: gray;
        }

        &.active {
            background-color: #8d9de9;

            .bx-pager-link {
                color: white;
            }

            .arrow {
                background-color: #8d9de9;
            }
        }
    }
}

.bx-pager {
    /* For most modern browsers (Chrome, Safari, Edge) */
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.bx-pager::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.main-option{
    border: 1px solid #e7eaf0;
}

.options{
    border-bottom: 1px solid #e7eaf0;
}

.values{
    border: 1px solid #e7eaf0;
    border-left: 0;
}
/* 
.main-option{
    border: 1px solid #d1d5db;
}

.options{
    border-bottom: 1px solid #d1d5db;
}

.values{
    border: 1px solid #d1d5db;
    border-left: 0;
} */


.active4{
    border-left: 4px solid royalblue;
    background: linear-gradient(to right, #e0e5e9, #a1c4fd, #5a7bdf);
}

.options h4{
    cursor: pointer;
}

.values{
    min-height: 20rem;
}

.inner-msg{
    position: relative;
    border: 1px solid #e7eaf0;
    padding: 0 5rem 0 0.5rem;
    background-color: #f5f9fc;
}

.score{
    position: absolute;
    top: 0;
    right: 0;
}

.log-details{
    border: 1px solid #e7eaf0;
    padding: .5rem;
}